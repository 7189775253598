import { IconProps } from '@fattureincloud/fic-design-system';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsights } from 'config/appInsight';
import React, { ErrorInfo } from 'react';
import { ErrorFallback } from './errorFallback';

export type Props = { action?: { text: string; icon?: IconProps; action: () => void } };
type State = { hasError: boolean };

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: errorInfo
    });
  }

  render() {
    const { action } = this.props;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback action={action} />;
    }

    return this.props.children;
  }
}
