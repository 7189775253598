import { GretchOptions } from 'gretchen';
import { PAGE_SIZE } from 'reducers/assignmentReducer';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'types/api';
import { Assignment } from 'types/assignment';
import { AssignmentListStatusEnum } from 'types/assignmentList';
import { ForwardRequest } from 'types/forwardAction';
import { APIManager, getAuthorizationInfo } from './utils';

export const get = async (token: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, headerConfig: {} };
  const additionalOpts: GretchOptions = {};
  const url = `/api/portaleFornitori/intervento/${encodeURIComponent(token)}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};

export const getList = async (state: AssignmentListStatusEnum, pageNumber: number) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, headerConfig: {}, justResponse: true };
  const additionalOpts: GretchOptions = {};
  const url = `/api/portaleFornitori/intervento?Stato=${state}&PageSize=${PAGE_SIZE}&PageNumber=${pageNumber}`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Response>(obj);
};

type AcceptAssignmentAPI = (dataPrevista: string, note: string) => Promise<Assignment | undefined>;

export const accept: AcceptAssignmentAPI = async (dataPrevista, note) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {} };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ dataPrevista, note }) };
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/accetta`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};

export const refuse = async (motivazione: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {} };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ motivazione }) };
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/rifiuta`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};

export const changeData = async (dataPrevista: string, motivazione: string) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {} };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ motivazione, dataPrevista }) };
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/dataPrevista`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};

type ConcludeAssignmentAPI = (dataEsecuzione: string, note: string, esito: boolean) => Promise<Assignment | undefined>;
export const conclude: ConcludeAssignmentAPI = async (dataEsecuzione, note, esito) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {} };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ dataEsecuzione, note, esito }) };
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/concludi`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};

type ForwardAssignmentAPI = (data: ForwardRequest) => Promise<Assignment | undefined>;
export const forward: ForwardAssignmentAPI = async (data) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {} };
  const additionalOpts: GretchOptions = { body: JSON.stringify(data) };
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/inoltra`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};
