import { MODAL_IDS } from 'types/modal';

/**
 * Action type names
 */
export enum ModalActionNames {
  OPEN_MODAL = 'OPEN_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL'
}

export type OpenModal = { type: ModalActionNames.OPEN_MODAL; payload: MODAL_IDS };

export type CloseModal = { type: ModalActionNames.CLOSE_MODAL };

/**
 * Action output typings aggregator
 */
export type ModalTypes = OpenModal | CloseModal;
