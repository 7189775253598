import { localStorage } from './localStorage';
import { cookie } from './cookies';
import { store } from 'config/store';
import { setAccessTokenAction } from 'actions/authorization';

const loadAuthData = (): boolean => {
  const refreshToken = cookie.refreshToken.get();
  const credentials = localStorage.credentials.get();
  const outcome = Boolean(refreshToken && credentials);
  return outcome;
};

const removeAllLoginData = () => {
  cookie.refreshToken.remove();
  localStorage.credentials.remove();
};

const updateAccessTokenInWholeApplication = (accessToken: string) => {
  const credentials = localStorage.credentials.get();
  if (credentials) {
    const updatedCredentials = { ...credentials, accessToken };
    localStorage.credentials.remove();
    localStorage.credentials.set(updatedCredentials);
    store.dispatch(setAccessTokenAction(accessToken));
  }
};

export const accountManager = {
  localStorage,
  cookie,
  loadAuthData,
  removeAllLoginData,
  updateAccessTokenInWholeApplication
};
