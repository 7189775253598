import { Button, Modal, ThemeProvider } from '@fattureincloud/fic-design-system';
import { deployEnvironment, DeployEnvironmentEnum } from 'config/deploy';
import React, { Component } from 'react';
import { Router } from './router';
import { register, unregister } from './serviceWorker';
import { theme } from 'palette';
import { faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { IS_NATIVE_APP } from 'utils/native';

declare global {
  interface Window {
    smartbanner: { publish: () => void };
  }
}

type State = { showUpdateModal: boolean; waitingServiceWorker: ServiceWorker | null };

interface ServiceWorkerEvent extends Event {
  target: (Partial<ServiceWorker> & EventTarget) | null;
}

// this class manage the service worker
export class App extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = { showUpdateModal: false, waitingServiceWorker: null };
  }

  private preventDefault = (event: Event) => {
    event.preventDefault();
    return null;
  };

  private removeNoIndex = () => {
    const metaTag = document.querySelector(`meta[name="robots"]`);
    metaTag && metaTag.remove();
  };

  private enableSmartBanner = () => {
    document.addEventListener('smartbanner.init', () => {
      if (!IS_NATIVE_APP) {
        try {
          setTimeout(() => {
            window.smartbanner.publish();
          }, 100);
        } catch (e) {}
      }
    });
  };

  componentDidMount() {
    this.enableSmartBanner();
    const isInProduction = deployEnvironment === DeployEnvironmentEnum.Production;
    if (isInProduction) {
      this.removeNoIndex();
      window.addEventListener('beforeinstallprompt', this.preventDefault);
      register({ onUpdate: this.onServiceWorkerUpdate });
      this.keepWatchingSW();
    } else {
      unregister();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.preventDefault);
  }

  // every hour check for a new app version
  private keepWatchingSW = () => {
    setInterval(() => {
      navigator?.serviceWorker?.getRegistrations().then((registrationsArray) => registrationsArray[0].update());
    }, 1000 * 60 * 60);
  };

  private onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    this.setState(
      {
        waitingServiceWorker: registration?.waiting,
        showUpdateModal: true
      },
      () => {
        const { waitingServiceWorker } = this.state;
        waitingServiceWorker?.addEventListener('statechange', (event: ServiceWorkerEvent) => {
          if (event?.target?.state === 'activated') {
            window.location.reload();
          }
        });
      }
    );
  };

  private updateServiceWorker = () => {
    const { waitingServiceWorker } = this.state;
    waitingServiceWorker?.postMessage({ type: 'SKIP_WAITING' });
    this.setState({ showUpdateModal: false });
  };

  render() {
    const { showUpdateModal } = this.state;
    return (
      <ThemeProvider theme={theme}>
        <Modal isOpen={showUpdateModal} isSmall className="new-Version-Modal">
          <h6>Nuova versione disponibile!</h6>
          <p className="mb-3">Clicca per aggiornare Danea Interventi alla nuova versione.</p>
          <Button
            iconRight={{ icon: faArrowUp }}
            text="Aggiorna"
            color="blue"
            onClick={() => this.updateServiceWorker()}
            type="secondary"
            fullWidth
          />
        </Modal>
        <Router />
      </ThemeProvider>
    );
  }
}
