import { GretchOptions } from 'gretchen';

export type IsLoading = { isLoading: boolean };

export type Authorization = [number | undefined, number | undefined];

export enum FetchMethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE'
}

export type HeaderConfig = {
  accessToken?: string;
  avoidContentTypeApplicationJson?: boolean;
};

export type APIRequestConfig = {
  method: FetchMethodEnum;
  headerConfig: HeaderConfig;
  justResponse?: boolean;
  alreadyTriedToRefresh?: boolean;
};

export type APIRequestObj = {
  url: string;
  requestConfig: APIRequestConfig;
  additionalOpts: GretchOptions;
};

export type APIError = {
  code: number;
  message: string;
  data?: unknown;
};

export type APIResponse<T> = { success: boolean; data: T; dispatchSuccessAction?: () => void };
