import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers, Reducer, Action } from 'redux';
import { History } from 'history';
import { AssignmentReducer, assignmentReducer } from './assignmentReducer';
import { MobileReducer, mobileReducer } from './mobileReducer';
import { ModalReducer, modalReducer } from './modalReducer';
import { authReducer, AuthReducer } from './authReducer';

export type RootState = {
  router: RouterState;
  assignmentReducer: AssignmentReducer;
  mobileReducer: MobileReducer;
  modalReducer: ModalReducer;
  authReducer: AuthReducer;
};

export const createRootReducer = (history: History): Reducer =>
  combineReducers({
    router: connectRouter(history),
    assignmentReducer,
    mobileReducer,
    modalReducer,
    authReducer
  });

/**
 * Reset all reducer to initial state after logout action
 * @param history
 */
export const rootReducer = (history: History) => (state: unknown, action: Action) => {
  if (action.type === 'LOGOUT') {
    state = undefined;
  }
  return createRootReducer(history)(state, action);
};
