import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';

const getIsLogged = (state: RootState) => state.authReducer.isLogged;
const isLogged = createSelector(getIsLogged, (res) => res);

const getUserEmail = (state: RootState) => state.authReducer.email;
const userEmail = createSelector(getUserEmail, (res) => res);

const getAccessToken = (state: RootState) => state.authReducer.accessToken;
const accessToken = createSelector(getAccessToken, (res) => res);

export const authSelector = { isLogged, userEmail, accessToken };
