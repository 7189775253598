import { colors } from './colors';
import { ButtonPalette } from '@fattureincloud/fic-design-system/dist/components/buttons/button/buttonPalette';
import { IconButtonPalette } from '@fattureincloud/fic-design-system/dist/components/buttons/iconButton/iconButtonPalette';
import { TagPalette } from '@fattureincloud/fic-design-system/dist/components/tag/tagPalette';
import buttonPalette from './components/buttonPalette';
import iconButtonPalette from './components/iconButtonPalette';
import tagPalette from './components/tagPalette';
import { MicroTagPalette } from '@fattureincloud/fic-design-system/dist/components/microTag/microTagPalette';
import { IconPalette } from '@fattureincloud/fic-design-system/dist/components/icon/iconPalette';
import microTagPalette from './components/microTagPalette';
import iconPalette from './components/iconPalette';
import {
  CheckboxPalette,
  DropdownPalette,
  IconBackgroundPalette,
  InputHelperPalette,
  InputTextPalette,
  SelectPalette,
  SidebarItemPalette,
  StepperPalette,
  TextAreaPalette,
  TooltipPalette
} from '@fattureincloud/fic-design-system';
import checkboxPalette from './components/checkboxPalette';
import dropdownPalette from './components/dropdownPalette';
import iconBackgroundPalette from './components/iconBackgroundPalette';
import sidebarItemPalette from './components/sidebarItemPalette';
import tooltipPalette from './components/tooltipPalette';
import stepperPalette from './components/stepperPalette';
import inputTextPalette from './components/inputTextPalette';
import textAreaPalette from './components/textAreaPalette';
import inputHelperPalette from './components/inputHelperPalette';
import selectPalette from './components/selectPalette';

type Components = {
  tag: TagPalette;
  button: ButtonPalette;
  iconButton: IconButtonPalette;
  microTag: MicroTagPalette;
  icon: IconPalette;
  checkbox: CheckboxPalette;
  dropdown: DropdownPalette;
  iconBackground: IconBackgroundPalette;
  sidebarItem: SidebarItemPalette;
  tooltip: TooltipPalette;
  stepper: StepperPalette;
  inputText: InputTextPalette;
  textArea: TextAreaPalette;
  inputHelper: InputHelperPalette;
  select: SelectPalette;
};
const components: Components = {
  tag: tagPalette,
  button: buttonPalette,
  iconButton: iconButtonPalette,
  microTag: microTagPalette,
  icon: iconPalette,
  checkbox: checkboxPalette,
  dropdown: dropdownPalette,
  iconBackground: iconBackgroundPalette,
  sidebarItem: sidebarItemPalette,
  tooltip: tooltipPalette,
  stepper: stepperPalette,
  inputText: inputTextPalette,
  textArea: textAreaPalette,
  inputHelper: inputHelperPalette,
  select: selectPalette
};

export const defaultPalette = colors;
export const theme = { palette: colors, components };
