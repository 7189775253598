import { REFRESH_TOKEN_COOKIE } from 'config/cookie';
import { getCookie, setCookie } from './index';

const set = (value: string, keepMeLogged: boolean) => {
  const expirationsDays = 14;
  setCookie(REFRESH_TOKEN_COOKIE, value, keepMeLogged ? undefined : expirationsDays);
};

const get = () => {
  const refreshToken = getCookie(REFRESH_TOKEN_COOKIE);
  return refreshToken;
};

const remove = () => setCookie(REFRESH_TOKEN_COOKIE, '', -1);

export const refreshToken = { set, get, remove };
