import { TABLET_WIDTH_BREAKPOINT } from 'config/mobile';
import { MobileTypes, MobileActionNames } from 'actions/mobile/types';

export type MobileReducer = { isMobileScreen: boolean };

const initialState: MobileReducer = { isMobileScreen: window.innerWidth < TABLET_WIDTH_BREAKPOINT };

export const mobileReducer = (state: MobileReducer = initialState, action: MobileTypes): MobileReducer => {
  switch (action.type) {
    case MobileActionNames.SCREEN_RESIZE:
      return { ...state, isMobileScreen: action.payload };
    default:
      return state;
  }
};
