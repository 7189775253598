import { GretchOptions } from 'gretchen';
import { APIRequestConfig, APIRequestObj, FetchMethodEnum } from 'types/api';
import { APIManager } from './utils';

type FirebaseDeviceAPI = (token: string) => Promise<boolean>;

export const add: FirebaseDeviceAPI = async (token) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, headerConfig: {}, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ token, device: 'web' }) };
  const url = `/api/portaleFornitori/notifiche/addDevice`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const response = await APIManager<Response>(obj);
  return Boolean(response?.ok);
};

export const remove: FirebaseDeviceAPI = async (token) => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.POST, headerConfig: {}, justResponse: true };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ token }) };
  const url = `/api/portaleFornitori/notifiche/removeDevice`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  const response = await APIManager<Response>(obj);
  return Boolean(response?.ok);
};
