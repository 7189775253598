import { AssignmentActionNames, AssignmentTypes } from 'actions/assignment/types';
import { Assignment } from 'types/assignment';
import { Pagination, AssignmentListStatusEnum, AssignmentsInfo, PageStatus } from 'types/assignmentList';

export type AssignmentReducer = {
  assignment?: Assignment;
  pageStatus: PageStatus;
};

export const PAGE_SIZE = 15;
export const initialPagination: Pagination = { totalCount: 0, pageSize: PAGE_SIZE, currentPage: 1, totalPages: 1 };
const initialAssignmentsInfo: AssignmentsInfo = { totalPending: 0, totalWorking: 0, totalCompleted: 0 };

const initialPageStatus: PageStatus = {
  currentStatus: AssignmentListStatusEnum.toAccept,
  pagination: { ...initialPagination },
  assignmentsInfo: { ...initialAssignmentsInfo }
};
const initialState: AssignmentReducer = { assignment: undefined, pageStatus: { ...initialPageStatus } };

export const assignmentReducer = (
  state: AssignmentReducer = initialState,
  action: AssignmentTypes
): AssignmentReducer => {
  switch (action.type) {
    case AssignmentActionNames.SET_CURRENT_ASSIGNMENT:
      return { ...state, assignment: action.payload };

    case AssignmentActionNames.UNSET_CURRENT_ASSIGNMENT:
      return { ...state, assignment: undefined };

    case AssignmentActionNames.SET_ASSIGNMENT_PAGE_STATUS:
      return { ...state, pageStatus: action.payload };

    default:
      return state;
  }
};
