import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from 'config/history';
import { deployEnvironment, DeployEnvironmentEnum } from 'config/deploy';

const PROD_KEY = 'ab9429f1-2e7c-4270-ac82-fd6db14a14ac';
const DEV_KEY = '42c50ec5-0455-408c-963e-a8356fb753f8';
const instrumentationKey = deployEnvironment === DeployEnvironmentEnum.Production ? PROD_KEY : DEV_KEY;

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history }
    },
    disableAjaxTracking: true
  }
});

const initializeAppInsights = () => {
  if (process.env.NODE_ENV === 'production') {
    appInsights.loadAppInsights();
  }
};

export { reactPlugin, appInsights, initializeAppInsights };
