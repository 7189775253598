export enum routesInMenu {
  home = '/',
  assignmentByToken = '/:token',
  privacyPolicy = '/privacy_policy',
  // Login route context
  login = '/login',
  reset = '/reset',
  register = '/register',
  forgotPassword = '/forgotPassword'
}
