import { LocalStorageAuthData } from 'types/account';
import { LocalStorageKeys } from 'constants/localStorageKeys';

const get = (): LocalStorageAuthData | undefined => {
  const data = localStorage.getItem(LocalStorageKeys.DANEA_INTERVENTI_WEB_SESSION_KEY);
  return data ? (JSON.parse(data) as LocalStorageAuthData) : undefined;
};

const set = (data: LocalStorageAuthData) =>
  localStorage.setItem(LocalStorageKeys.DANEA_INTERVENTI_WEB_SESSION_KEY, JSON.stringify(data));

const remove = () => localStorage.removeItem(LocalStorageKeys.DANEA_INTERVENTI_WEB_SESSION_KEY);

export const credentials = { get, set, remove };
