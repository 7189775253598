import { AuthData, KeepMeLogged, LoginResult } from 'types/account';

export enum AuthActionNames {
  SET_LOGIN_TOKEN = 'SET_LOGIN_TOKEN',
  SET_AUTH_DATA = 'SET_AUTH_DATA',
  MANAGE_API_LOGIN_API_DATA = 'MANAGE_API_LOGIN_DATA',
  LOGOUT = 'LOGOUT',
  JUST_KEEP_EMAIL = 'JUST_KEEP_EMAIL'
}

export type SetLoginToken = { type: typeof AuthActionNames.SET_LOGIN_TOKEN; payload: string };

export type SetAuthData = { type: typeof AuthActionNames.SET_AUTH_DATA; payload: AuthData };

export type ManageAPILoginData = {
  type: typeof AuthActionNames.MANAGE_API_LOGIN_API_DATA;
  payload: LoginResult & KeepMeLogged;
};

export type Logout = { type: typeof AuthActionNames.LOGOUT };

export type JustKeepEmail = { type: typeof AuthActionNames.JUST_KEEP_EMAIL };

export type AuthActionTypes = SetLoginToken | SetAuthData | ManageAPILoginData | Logout | JustKeepEmail;
