import { GretchOptions } from 'gretchen';
import { APIRequestConfig, FetchMethodEnum, APIRequestObj } from 'types/api';
import { Assignment } from 'types/assignment';
import { Integration } from 'types/integration';
import { APIManager, getAuthorizationInfo } from './utils';
import moment from 'moment';
import 'moment/locale/it';

export const getIntegretionsAPI = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.GET, headerConfig: {} };
  const additionalOpts: GretchOptions = {};
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/integrazione`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Integration[]>(obj);
};

export const addIntegretionsAPI = async (messaggio: string, file?: File) => {
  const [pid, interventoID] = getAuthorizationInfo();
  const formData = new FormData();
  formData.append('messaggio', messaggio);
  // convert file to binary
  if (file) {
    formData.append('allegato', file, file.name);
  }

  const requestConfig: APIRequestConfig = {
    method: FetchMethodEnum.POST,
    headerConfig: { avoidContentTypeApplicationJson: true }
  };
  const additionalOpts: GretchOptions = { body: formData };
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/integrazione`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };

  return await APIManager<Integration[]>(obj);
};

// notification of reading
export const receipt = async () => {
  const requestConfig: APIRequestConfig = { method: FetchMethodEnum.PUT, headerConfig: {} };
  const additionalOpts: GretchOptions = { body: JSON.stringify({ dataLettura: moment().toDate() }) };
  const [pid, interventoID] = getAuthorizationInfo();
  const url = `/api/portaleFornitori/intervento/${pid}/${interventoID}/confermaLettura`;
  const obj: APIRequestObj = { url, requestConfig, additionalOpts };
  return await APIManager<Assignment>(obj);
};
