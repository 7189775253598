import { color } from '@fattureincloud/fic-design-system/dist/styles/types';

export const orange: color = {
  50: '#fff3e4',
  100: '#ffe0bc',
  200: '#ffcc8f',
  300: '#ffb862',
  400: '#ffa841',
  500: '#ff991f',
  600: '#ff911b',
  700: '#ff8617',
  800: '#ff7c12',
  900: '#ff6b0a'
};
