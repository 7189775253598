import { IconButtonPalette } from '@fattureincloud/fic-design-system/dist/components/buttons/iconButton/iconButtonPalette';
import { colors as defaultPalette } from 'palette/colors';

const iconButtonPalette: IconButtonPalette = {
  primary: {
    blue: {
      normal: defaultPalette.blue[300],
      hover: defaultPalette.blue[500]
    },
    red: {
      normal: defaultPalette.red[300],
      hover: defaultPalette.red[500]
    },
    green: {
      normal: defaultPalette.green[300],
      hover: defaultPalette.green[500]
    },
    yellow: {
      normal: defaultPalette.yellow[300],
      hover: defaultPalette.yellow[500]
    },
    disabled: {
      normal: defaultPalette.grey[300],
      hover: defaultPalette.grey[300]
    }
  },
  secondary: {
    blue: {
      normal: defaultPalette.blue[300],
      hover: defaultPalette.white[100]
    },
    red: {
      normal: defaultPalette.red[300],
      hover: defaultPalette.white[100]
    },
    green: {
      normal: defaultPalette.green[300],
      hover: defaultPalette.white[100]
    },
    yellow: {
      normal: defaultPalette.yellow[300],
      hover: defaultPalette.white[100]
    },
    disabled: {
      normal: defaultPalette.grey[300],
      hover: defaultPalette.grey[300]
    }
  },
  defaultType: 'primary',
  defaultColor: 'blue'
};

export default iconButtonPalette;
