import React from 'react';
import styled from 'styled-components';
import { Props as SpinnerProps, Spinner } from './spinner';

type OverlayProps = { position: 'fixed' | 'absolute'; transparentBackground?: boolean };
type Props = Partial<SpinnerProps> & OverlayProps;

const Overlay = styled.div<OverlayProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  position: ${({ position }) => position};
  background: ${({ transparentBackground }) => (transparentBackground ? 'transparent' : 'rgba(0, 0, 0, 0.14)')};
`;

export const LoadingSpinner: React.FC<Props> = ({ position, size = 'medium' }) => (
  <Overlay position={position}>
    <Spinner size={size} />
  </Overlay>
);

export default LoadingSpinner;
