/**
 * Action type names
 */
export enum MobileActionNames {
  SCREEN_RESIZE = 'SCREEN_RESIZE'
}

export type CheckMobileScreen = { type: MobileActionNames.SCREEN_RESIZE; payload: boolean };

/**
 * Action output typings aggregator
 */
export type MobileTypes = CheckMobileScreen;
