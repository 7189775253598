import { DOMUSTUDIOAPI_BASE_PATH } from 'config/api';
import { LoginResult } from 'types/account';
import { APIErrorNotify } from './utils';

type Success = { accessToken: string };
type Response = string | undefined;

export const refreshAccessToken = async (refreshToken: string): Promise<Response> => {
  const headers = new Headers();
  headers.set('content-type', 'application/json');
  const response = await fetch(`${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/refresh`, {
    method: 'POST',
    headers,
    body: JSON.stringify(refreshToken)
  });

  if (response.ok) {
    let result = undefined;
    try {
      result = ((await response.json()) as Success)?.accessToken;
    } catch (e) {
      APIErrorNotify();
      return;
    } finally {
      return result;
    }
  }
  return undefined;
};

export const login = async (email: string, pwd: string): Promise<LoginResult> => {
  const response = await fetch(`${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/login`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, pwd })
  });
  const { ok } = response;
  try {
    const result = await response.json();
    return { success: ok, data: result };
  } catch (error) {
    APIErrorNotify();
    return { success: false, data: undefined };
  }
};

export const logout = async (accessToken: string, refreshToken: string) => {
  const response = await fetch(`${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/logout`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', authorization: `Bearer ${accessToken}` },
    body: JSON.stringify({ refreshToken })
  });
  return response;
};
