import React, { FunctionComponent, useEffect } from 'react';
import { Props, connector } from './types';
import { TABLET_WIDTH_BREAKPOINT } from 'config/mobile';
import { throttle } from 'lodash';

const MS_TIMEOUT = 200;

const ScreenDetectorComponent: FunctionComponent<Props> = ({ checkIsMobileScreenAction, children }) => {
  const handleScreenResize = () => checkIsMobileScreenAction(window.innerWidth < TABLET_WIDTH_BREAKPOINT);
  useEffect(() => {
    window.addEventListener('resize', throttle(handleScreenResize, MS_TIMEOUT));
    return window.removeEventListener('resize', throttle(handleScreenResize, MS_TIMEOUT));
  });

  return <>{children}</>;
};

export const ScreenDetector = connector(ScreenDetectorComponent);
