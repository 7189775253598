import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { RootState, rootReducer } from '../reducers/rootReducer';
import { history } from './history';
import { authListener } from 'middlewares/authListener';

function configureStore(preloadedState: RootState | {}): Store {
  const store = createStore(
    rootReducer(history),
    preloadedState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), authListener))
  );

  return store;
}

export const store = configureStore({});
