export enum AssignmentListStatusEnum {
  'toAccept' = 1,
  'inProgress',
  'completed',
  'forwarded'
}

export type Pagination = {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
};

export type AssignmentsInfo = {
  totalPending: number;
  totalWorking: number;
  totalCompleted: number;
};

export type PageStatus = {
  currentStatus: AssignmentListStatusEnum;
  assignmentsInfo: AssignmentsInfo;
  pagination: Pagination;
};
