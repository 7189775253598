import { Assignment } from 'types/assignment';
import { PageStatus } from 'types/assignmentList';

/**
 * Action type names
 */
export enum AssignmentActionNames {
  SET_CURRENT_ASSIGNMENT = 'SET_CURRENT_ASSIGNMENT',
  UNSET_CURRENT_ASSIGNMENT = 'UNSET_CURRENT_ASSIGNMENT',
  SET_ASSIGNMENT_PAGE_STATUS = 'SET_ASSIGNMENT_PAGE_STATUS'
}

export type SetCurrentAssignment = {
  type: AssignmentActionNames.SET_CURRENT_ASSIGNMENT;
  payload: Assignment;
};

export type UnsetCurrentAssignment = { type: AssignmentActionNames.UNSET_CURRENT_ASSIGNMENT };

export type SetAssignmentPageStatus = {
  type: AssignmentActionNames.SET_ASSIGNMENT_PAGE_STATUS;
  payload: PageStatus;
};

/**
 * Action output typings aggregator
 */
export type AssignmentTypes = SetCurrentAssignment | SetAssignmentPageStatus | UnsetCurrentAssignment;
