import { FIREBASE_TOKEN_COOKIE } from 'config/cookie';
import { getCookie, setCookie } from './index';

const set = (value: string) => {
  setCookie(FIREBASE_TOKEN_COOKIE, value, undefined);
};

const get = () => {
  const firebaseToken = getCookie(FIREBASE_TOKEN_COOKIE);
  return firebaseToken;
};

const remove = () => setCookie(FIREBASE_TOKEN_COOKIE, '', -1);

export const firebaseToken = { set, get, remove };
