import { AuthData, LoginResult } from 'types/account';
import { AuthActionNames, JustKeepEmail, Logout, ManageAPILoginData, SetAuthData, SetLoginToken } from './types';

export const setAccessTokenAction = (accessToken: string): SetLoginToken => ({
  type: AuthActionNames.SET_LOGIN_TOKEN,
  payload: accessToken
});

export const setLoginDataAction = (data: AuthData): SetAuthData => ({
  type: AuthActionNames.SET_AUTH_DATA,
  payload: data
});

export const manageAPILoginDataAction = (data: LoginResult, keepMeLogged: boolean): ManageAPILoginData => ({
  type: AuthActionNames.MANAGE_API_LOGIN_API_DATA,
  payload: { ...data, keepMeLogged }
});

export const logoutAction = (): Logout => ({ type: AuthActionNames.LOGOUT });

export const justKeepEmailAction = (): JustKeepEmail => ({ type: AuthActionNames.JUST_KEEP_EMAIL });
