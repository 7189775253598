import { setLoginDataAction } from 'actions/authorization';
import { api } from 'api';
import { store } from 'config/store';
import { RootState } from 'reducers/rootReducer';
import { accountManager } from './accountManager';
import { cookie } from './accountManager/cookies';

export const isAuthenticated = (): boolean => {
  const state = store.getState() as RootState;
  const { accessToken: reduxAccessToken } = state.authReducer;
  const refreshToken = accountManager.cookie.refreshToken.get();
  const { accessToken, email } = accountManager.localStorage.credentials.get() ?? {};
  if (!refreshToken || !accessToken) {
    return false;
  }
  if (email && reduxAccessToken !== accessToken) {
    store.dispatch(setLoginDataAction({ accessToken, refreshToken, email }));
  }
  return true;
};

export const clearFirebaseToken = async () => {
  const token = cookie.firebaseToken.get();
  if (token) {
    await api.firebaseDevice.remove(token);
    cookie.firebaseToken.remove();
  }
};
