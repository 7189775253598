import { InputHelperPalette } from '@fattureincloud/fic-design-system';
import { colors as defaultPalette } from 'palette/colors';

const inputHelperPalette: InputHelperPalette = {
  normal: {
    color: defaultPalette.black[100],
    background: defaultPalette.grey[100]
  },
  error: {
    color: defaultPalette.red[900],
    background: defaultPalette.red[50]
  },
  warning: {
    color: defaultPalette.yellow[900],
    background: defaultPalette.yellow[50]
  },
  info: {
    color: defaultPalette.blue[900],
    background: defaultPalette.blue[50]
  },
  success: {
    color: defaultPalette.green[900],
    background: defaultPalette.green[50]
  }
};

export default inputHelperPalette;
