import { DOMUSTUDIOAPI_BASE_PATH } from 'config/api';
import { APIError } from 'types/api';
import { APIErrorNotify } from './utils';

export const requestRegistration = async (email: string): Promise<Response> => {
  const response = await fetch(
    `${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/requestRegistration?email=${encodeURIComponent(email)}`,
    {
      method: 'POST'
    }
  );
  return response;
};

export const requestPasswordReset = async (email: string): Promise<boolean> => {
  const response = await fetch(
    `${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/requestPasswordReset?email=${encodeURIComponent(email)}`,
    {
      method: 'POST'
    }
  );
  const { status } = response;
  if (status === 200 || status === 204) {
    return true;
  }
  try {
    const error = (await response.json()) as APIError;
    APIErrorNotify(error?.message);
  } catch (error) {
    APIErrorNotify('');
  } finally {
    return false;
  }
};

export const validateToken = async (token: string): Promise<Response | undefined> => {
  const response = await fetch(`${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/validateToken?token=${token}`);
  const { status } = response;
  if (status === 200 || status === 204) {
    return response;
  }
  try {
    const error = (await response.json()) as APIError;
    APIErrorNotify(error?.message);
  } catch (error) {
    APIErrorNotify('');
  } finally {
    return undefined;
  }
};

export const managePassword = async (token: string, password: string, register: boolean): Promise<boolean> => {
  const response = await fetch(
    `${DOMUSTUDIOAPI_BASE_PATH}/api/portaleFornitori/${
      register ? 'register' : 'resetPassword'
    }?token=${encodeURIComponent(token)}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password })
    }
  );
  const { status, ok } = response;
  if (ok || status === 200 || status === 204) {
    return true;
  }
  try {
    const error = (await response.json()) as APIError;
    APIErrorNotify(error?.message);
  } catch (error) {
    APIErrorNotify('');
  } finally {
    return false;
  }
};
