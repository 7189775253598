import { useCallback, useEffect } from 'react';
import icon from 'assets/notification.png';
import { showErrorToast } from 'utils/toast';
import { api } from 'api';
import { accountManager } from 'utils/accountManager';
import 'firebase/messaging';
import { ControllerProps } from './controller';

const ERROR_MESSAGE =
  'Non è possibile abilitare le notifiche sul dispositivo. Ricarica la pagina o verifica lo stato dell tua rete.';

type Props = { enable: boolean } & ControllerProps;
export const useFirebaseNotificationDispatcher = ({ messaging, enable }: Props) => {
  const getToken = useCallback(async () => {
    let currentToken = '';
    try {
      currentToken = await messaging.getToken();
      !currentToken && showErrorToast(ERROR_MESSAGE);
    } catch (error) {
      console.log(error);
    }
    return currentToken;
  }, [messaging]);

  const updateTokenToApp = async (token: string) => {
    if (token?.length) {
      const success = await api.firebaseDevice.add(token);
      success && accountManager.cookie.firebaseToken.set(token);
      return success;
    }
    return false;
  };

  const setToken = useCallback(async () => {
    const token = await getToken();
    const result = await updateTokenToApp(token);
    return result;
  }, [getToken]);

  const registration = useCallback(async () => {
    const successRegistration = await setToken();
    if (successRegistration) {
      messaging.onMessage(({ notification }) => {
        if (notification) {
          const { title, body } = notification;
          new Notification(title, { body, icon: icon, image: icon });
        }
      });
    }
  }, [setToken, messaging]);

  useEffect(() => {
    if (enable) {
      registration();
    }
  }, [enable, registration]);

  return null;
};
