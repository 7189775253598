import { deployEnvironment, DeployEnvironmentEnum } from 'config/deploy';
import { routesInMenu } from 'constants/routesInMenu';

export const isLoginContextRoute = (route: string): boolean =>
  route === routesInMenu.login ||
  route === routesInMenu.reset ||
  route === routesInMenu.register ||
  route === routesInMenu.forgotPassword;

export const isDevelopmentEnvironment = () => deployEnvironment !== DeployEnvironmentEnum.Production;
export const isProductionEnvironment = () => deployEnvironment !== DeployEnvironmentEnum.Production;
