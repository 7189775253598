import { push } from 'connected-react-router';
import { Middleware } from 'redux';
import { AuthActionNames } from 'actions/authorization/types';
import { routesInMenu } from 'constants/routesInMenu';
import { accountManager } from 'utils/accountManager';
import { RootState } from 'reducers/rootReducer';
import { AuthData, LocalStorageAuthData } from 'types/account';
import { api } from 'api';
import { setLoginDataAction } from 'actions/authorization';
import { APIError } from 'types/api';
import { APIErrorNotify } from 'api/utils';
import { clearFirebaseToken } from 'utils/authentication';

export const authListener: Middleware = (store) => (next) => (action) => {
  // If logout remove the tokens from the browser
  if (action.type === AuthActionNames.LOGOUT) {
    const { accessToken } = (store.getState() as RootState).authReducer;
    const refreshToken = accountManager.cookie.refreshToken.get();
    refreshToken && api.authorization.logout(accessToken, refreshToken);
    clearFirebaseToken();
    accountManager.removeAllLoginData();
    store.dispatch(push(routesInMenu.login));
  }

  // Handle internal login success
  if (action.type === AuthActionNames.MANAGE_API_LOGIN_API_DATA) {
    const { success, data, keepMeLogged } = action.payload;
    if (success && data) {
      const { accessToken, refreshToken, email } = data as AuthData;
      const localStorageAuthData: LocalStorageAuthData = { accessToken, email };
      // Load data on browser
      if (refreshToken) {
        accountManager.cookie.refreshToken.set(refreshToken, keepMeLogged);
      }
      accountManager.localStorage.credentials.set(localStorageAuthData);
      // Load data on redux
      store.dispatch(setLoginDataAction({ ...localStorageAuthData, refreshToken }));
      store.dispatch(push(routesInMenu.home));
    } else if (!success && data) {
      APIErrorNotify((data as APIError).message);
    } else {
      APIErrorNotify();
    }
  }

  /**
   * ########
   * Dispatch current action
   * ########
   */
  const result = next(action);
  return result;
};
