import { AuthActionNames, AuthActionTypes } from 'actions/authorization/types';
import { IsLogged, LocalStorageAuthData } from 'types/account';

export type AuthReducer = LocalStorageAuthData & IsLogged;
const initialState: AuthReducer = { accessToken: '', email: '', isLogged: false };

export const authReducer = (state: AuthReducer = initialState, action: AuthActionTypes): AuthReducer => {
  switch (action.type) {
    case AuthActionNames.SET_AUTH_DATA: {
      const { refreshToken, ...rest } = action.payload;
      return { ...state, ...rest, isLogged: true };
    }

    case AuthActionNames.SET_LOGIN_TOKEN:
      return { ...state, accessToken: action.payload };

    case AuthActionNames.JUST_KEEP_EMAIL:
      return { ...initialState, email: state.email };

    case AuthActionNames.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};
