import { connect, ConnectedProps } from 'react-redux';
import { setLoginDataAction, logoutAction } from 'actions/authorization';
import { RouteProps } from 'react-router';
import { RootState } from 'reducers/rootReducer';
import { IsLoading } from 'types/api';

const mapStateToProps = ({ authReducer: { accessToken, isLogged } }: RootState) => ({ accessToken, isLogged });
const mapDispatchToProps = { setLoginDataAction, logoutAction };

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type Props = RouteProps & ConnectedProps<typeof connector>;
export type State = IsLoading;
