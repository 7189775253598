import { RootState } from 'reducers/rootReducer';
import { HeaderConfig } from 'types/api';
import { store } from './store';
import { deployEnvironment, DeployEnvironmentEnum } from 'config/deploy';

const URL = 'https://domustudioprovidersapi.danea.it';
const DEV_URL = 'https://dev-domustudioprovidersapi.danea.it';

export const DOMUSTUDIOAPI_BASE_PATH = deployEnvironment === DeployEnvironmentEnum.Production ? URL : DEV_URL;

export const getAuthorizationHeader = (config?: HeaderConfig) => {
  const { accessToken, avoidContentTypeApplicationJson } = config ?? {};
  const requestHeaders: { [key: string]: string } = {};
  const {
    authReducer: { accessToken: reduxAccessToken }
  } = store.getState() as RootState;
  if (accessToken || reduxAccessToken) {
    requestHeaders['authorization'] = `Bearer ${accessToken ?? reduxAccessToken}`;
  }
  if (!avoidContentTypeApplicationJson) {
    requestHeaders['content-type'] = 'application/json';
  }
  return requestHeaders;
};
