import { refreshToken } from './refreshToken';
import { firebaseNotification } from './firebaseNotification';
import { firebaseToken } from './firebaseToken';

// Cookie basic methods
export const setCookie = (name: string, value: string, expirationDays?: number) => {
  let expires = '';
  if (expirationDays !== undefined) {
    let date = 'Thu, 01 Jan 1970 00:00:00 GMT';
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    date = expirationDate.toUTCString();
    expires = `expires=${date};`;
  }
  const cookie = `${name}=${value};${expires ?? ''}path=/;`;
  document.cookie = cookie;
};

export const getCookie = (cookieName: string) => {
  const cookiesList = decodeURIComponent(document.cookie);
  const value = cookiesList
    ?.split('; ')
    ?.find((row) => row.startsWith(cookieName))
    ?.split('=')[1];
  return value;
};

export const cookie = { refreshToken, firebaseNotification, firebaseToken };
