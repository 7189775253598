import { FIREBASE_NOTIFICATION_COOKIE } from 'config/cookie';
import { getCookie, setCookie } from './index';

const set = (days: number) => {
  setCookie(FIREBASE_NOTIFICATION_COOKIE, 'enabled', days);
};

const get = () => {
  const firebaseToken = getCookie(FIREBASE_NOTIFICATION_COOKIE);
  return firebaseToken;
};

const remove = () => setCookie(FIREBASE_NOTIFICATION_COOKIE, '', -1);

export const firebaseNotification = { set, get, remove };
