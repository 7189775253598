import { paletteColor } from '@fattureincloud/fic-design-system';
import {
  checkboxParts,
  checkboxState,
  checkboxStatus
} from '@fattureincloud/fic-design-system/dist/components/checkbox/types';
import { colors as defaultPalette } from 'palette/colors';

export type CheckboxPalette = {
  input: {
    [k in checkboxState]: {
      [k in checkboxStatus]: {
        [k in checkboxParts]: paletteColor;
      };
    };
  };
  label: {
    [k in checkboxState]: paletteColor;
  };
};

const normalChecked = {
  tickColor: defaultPalette.white[100],
  backgroundColor: defaultPalette.blue[500],
  borderColor: defaultPalette.blue[500]
};

const errorChecked = {
  tickColor: defaultPalette.white[100],
  backgroundColor: defaultPalette.red[500],
  borderColor: defaultPalette.red[500]
};

const checkboxPalette: CheckboxPalette = {
  input: {
    normal: {
      checked: normalChecked,
      indeterminate: normalChecked,
      unchecked: {
        tickColor: defaultPalette.white[100],
        backgroundColor: 'transparent',
        borderColor: defaultPalette.grey[500]
      }
    },
    error: {
      checked: errorChecked,
      indeterminate: errorChecked,
      unchecked: {
        tickColor: defaultPalette.white[100],
        backgroundColor: 'transparent',
        borderColor: defaultPalette.red[500]
      }
    }
  },
  label: {
    normal: defaultPalette.black[100],
    error: defaultPalette.red[500]
  }
};

export default checkboxPalette;
