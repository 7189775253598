import { logoutAction } from 'actions/authorization';
import { api } from 'api';
import { getGretchOptions } from 'config/gretchen';
import { store } from 'config/store';
import { ErrorsCodeEnum } from 'constants/apiErrorCodes';
import { gretch, GretchOptions } from 'gretchen';
import { RootState } from 'reducers/rootReducer';
import { APIError, APIRequestObj, Authorization } from 'types/api';
import { accountManager } from 'utils/accountManager';
import { showErrorToast } from 'utils/toast';

export const APIErrorNotify = (message?: string) =>
  showErrorToast('Errore', message ?? 'Abbiamo riscontrato un errore inaspettato, riprova tra qualche istante');

export const getAuthorizationInfo = (): Authorization => {
  const state: RootState = store.getState();
  const assignment = state.assignmentReducer?.assignment;
  return [assignment?.pid, assignment?.interventoID];
};

const getAPIInstance = <T>(APIObj: APIRequestObj) => {
  const { url, requestConfig, additionalOpts } = APIObj;
  const opts: GretchOptions = { ...getGretchOptions(requestConfig), ...additionalOpts };
  return () => gretch<T, APIError>(url, opts);
};

export const APIManager = async <T>(APIObj: APIRequestObj): Promise<T | undefined> => {
  const instance = getAPIInstance<T>(APIObj);
  const { response, status } = await instance().flush();
  try {
    const { alreadyTriedToRefresh, justResponse } = APIObj.requestConfig;
    if (response.ok) {
      if (justResponse) {
        // type T must be Response type for working
        return (response as unknown) as T;
      }
      const data = await response.json();
      return data as T;
    }
    const error = (await response.json()) as APIError;
    if (error && error.hasOwnProperty('message')) {
      // handle unauthorized case
      if (
        !alreadyTriedToRefresh &&
        status === 401 &&
        'code' in error &&
        error.code === ErrorsCodeEnum.NOT_AUTHENTICADED
      ) {
        const refreshToken = accountManager.cookie.refreshToken.get();
        const newAccessToken = refreshToken ? await api.authorization.refreshAccessToken(refreshToken) : undefined;
        if (newAccessToken) {
          accountManager.updateAccessTokenInWholeApplication(newAccessToken);
          // update APIObj with new accessToken
          const newAPIObj = { ...APIObj };
          newAPIObj.requestConfig.headerConfig.accessToken = newAccessToken;
          newAPIObj.requestConfig.alreadyTriedToRefresh = true;
          return APIManager(newAPIObj);
        }
        // handle failed refresh token call
        APIErrorNotify(error.message);
        store.dispatch(logoutAction());
        return undefined;
      }
      APIErrorNotify(error.message);
    }
    APIErrorNotify();
    return undefined;
  } catch (e) {
    APIErrorNotify();
    return APIObj.requestConfig.justResponse ? ((response as unknown) as T) : undefined;
  }
};
