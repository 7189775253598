import { useEffect } from 'react';
import { IS_NATIVE_APP, reactNativePostMessage } from 'utils/native';
import { HookProps } from '../types';

export const useFirebaseMobile = ({ accessToken, refreshToken }: HookProps) => {
  useEffect(() => {
    if (IS_NATIVE_APP) {
      if (accessToken && refreshToken) {
        // emit to react-native to enable firebase on
        reactNativePostMessage({ accessToken, refreshToken });
      }
    }
  }, [accessToken, refreshToken]);

  return null;
};
