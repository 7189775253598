import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';

const getAssignment = (state: RootState) => state.assignmentReducer.assignment;
const getDetails = createSelector(getAssignment, (res) => res);

const getProviderEmail = createSelector(getAssignment, (res) => res?.fornitoreEmail);

const canForwardAssignment = createSelector(
  (state: RootState) => {
    const loggedEmail = state.authReducer.email;
    const providerEmail = state.assignmentReducer.assignment?.fornitoreEmail;
    return Boolean(loggedEmail && providerEmail && loggedEmail === providerEmail);
  },
  (res) => res
);

export const assignmentSelector = { getDetails, getProviderEmail, canForwardAssignment };
