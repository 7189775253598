import { Toast } from '@fattureincloud/fic-design-system';
import { ConnectedRouter } from 'connected-react-router';
import React, { lazy } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { Layout } from './components/layout';
import { store } from './config/store';
import { history } from './config/history';
import { ScreenDetector } from 'components/mobileScreenDetector';
import { SuspenseWrapper } from 'components/suspenceWrapper';
import { ErrorBoundary } from 'components/errorBoundary';
import { initializeAppInsights } from 'config/appInsight';
import { routesInMenu } from 'constants/routesInMenu';
import { PrivateRoute } from 'components/privateRoute';
import { FirebaseManager } from 'firebase-notification';

const LazyLoginRoute = lazy(() => import('routes/login'));
const LazyForgotPasswordRoute = lazy(() => import('routes/forgotPassword'));
const LazyAssignmentRoute = lazy(() => import('routes/assignment/assignmentRoute'));
const LazyHome = lazy(() => import('routes/home'));
const LazyManagePassword = lazy(() => import('routes/login/components/managePassword'));
const LazyPrivacyPolicy = lazy(() => import('routes/privacyPolicy'));
const LazyWrongTokenMessage = lazy(() => import('components/wrongTokenMessage'));

// Initialize application insights
initializeAppInsights();

export const Router = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Toast.Container position="bottom-right" />
      <ScreenDetector />
      <FirebaseManager />
      <SuspenseWrapper>
        <Layout>
          <ErrorBoundary>
            <Switch>
              {/* private route */}
              <PrivateRoute path={routesInMenu.home} render={() => <LazyHome />} exact />
              {/* pubblic route */}
              <Route path={routesInMenu.privacyPolicy} component={() => <LazyPrivacyPolicy />} />
              <Route path={routesInMenu.login} component={() => <LazyLoginRoute />} />
              <Route
                path={routesInMenu.forgotPassword}
                render={(props) => (
                  <LazyForgotPasswordRoute
                    email={decodeURIComponent(new URLSearchParams(props.location.search).get('email') || '')}
                  />
                )}
              />
              <Route
                path={[routesInMenu.reset, routesInMenu.register]}
                render={(props) => (
                  <LazyManagePassword
                    fromRegistration={props.location.pathname === routesInMenu['register']}
                    token={new URLSearchParams(props.location.search).get('token')}
                    email={decodeURIComponent(new URLSearchParams(props.location.search).get('email') || '')}
                  />
                )}
              />
              <Route
                path={routesInMenu.assignmentByToken}
                exact
                render={({ match }) => <LazyAssignmentRoute match={match} />}
              />
              <Route path="*" render={() => <LazyWrongTokenMessage />} />
            </Switch>
          </ErrorBoundary>
        </Layout>
      </SuspenseWrapper>
    </ConnectedRouter>
  </Provider>
);
