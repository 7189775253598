import React from 'react';
import { Redirect, Route } from 'react-router';
import LoadingSpinner from 'components/loadingSpinner';
import { connector, Props, State } from './types';
import { routesInMenu } from 'constants/routesInMenu';
import { isAuthenticated } from 'utils/authentication';

class AuthenticationWrapper extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { isLoading: true };
  }

  componentDidMount = () => this.checkAuth();

  componentDidUpdate(preProps: Props) {
    const { path } = this.props;
    if (path !== preProps.path) {
      this.checkAuth();
    }
  }

  private checkAuth = () => {
    const { isLogged } = this.props;
    if (!isLogged) {
      const isAuth = isAuthenticated();
      if (!isAuth) {
        this.props.logoutAction();
      }
    }
    this.setState({ isLoading: false });
  };

  render = () => {
    const { accessToken, isLogged, ...routeProps } = this.props;
    const { isLoading } = this.state;
    if (isLoading) {
      return <LoadingSpinner position="fixed" />;
    }
    if (!isLogged || !accessToken) {
      return <Redirect to={routesInMenu.login} from={routeProps?.location?.pathname} />;
    }
    return <Route {...routeProps} />;
  };
}

export const PrivateRoute = connector(AuthenticationWrapper);
