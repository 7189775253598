import { PageEmptySet } from '@fattureincloud/fic-design-system';
import React from 'react';
import { Props } from './index';
import { faExclamation } from '@fortawesome/pro-regular-svg-icons';

export const ErrorFallback: React.FC<Props> = ({ action }) => (
  <div className="h-100 w-100 pos-r d-flex justify-content-center align-items-center mt-5">
    <PageEmptySet
      title="Errore"
      text="Qualcosa è andato storto nella visualizzazione della pagina. Riprova tra qualche istante."
      icon={{ icon: faExclamation }}
      action={action}
    />
  </div>
);
