import { ModalTypes, ModalActionNames } from 'actions/modal/types';
import { MODAL_IDS } from 'types/modal';

export type ModalReducer = { currentModal?: MODAL_IDS };

const initialState: ModalReducer = { currentModal: undefined };

export const modalReducer = (state: ModalReducer = initialState, action: ModalTypes): ModalReducer => {
  switch (action.type) {
    case ModalActionNames.OPEN_MODAL: {
      document.body.style.overflow = 'hidden';
      return { ...state, currentModal: action.payload };
    }
    case ModalActionNames.CLOSE_MODAL: {
      document.body.style.overflow = 'visible';
      return { ...state, currentModal: undefined };
    }
    default:
      return state;
  }
};
