import React, { FC, lazy } from 'react';
import style from './layout.module.scss';
import { RootState } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';
import { isLoginContextRoute } from 'utils/route';
import { selectors } from 'utils/reduxSelector';
// import { PullToRefresh } from './components/pullToRefresh';

const LazyTopbar = lazy(() => import('components/topbar'));
const LazyFooter = lazy(() => import('components/footer'));

export const Layout: FC = ({ children }) => {
  const location = useSelector((state: RootState) => state.router.location);
  const isMobileScreen = useSelector(selectors.mobileSelector.isMobileScreen);
  const isLoginContext = isLoginContextRoute(location.pathname);
  return (
    // <PullToRefresh>
    isLoginContext ? (
      <div className={style['login-border']}>
        <div className={style['login-layout']}>{children}</div>
      </div>
    ) : (
      <div className={style['layout-container']}>
        <LazyTopbar />
        {isMobileScreen ? (
          <div className={style['container-mobile']}>
            <div className={style['content-mobile']}>{children}</div>
          </div>
        ) : (
          <div className={style['max-width']}>
            <div className={style.content}>{children}</div>
          </div>
        )}
        <LazyFooter />
      </div>
    )
    // </PullToRefresh>
  );
};
